import React from 'react';

import styles from './holding.module.scss';

const HoldingPage = () => (
  <div className={styles.Container}>
    <h1>DLPDigital</h1>
    <p>Under construction, please check back soon</p>
  </div>
);

export default HoldingPage;
